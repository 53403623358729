<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label for="start-date">From</label>
            <b-form-datepicker
              id="start-date"
              v-model="startDate"
              reset-button
              class="mb-2"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              @input="endDate=setEndDate(startDate, endDate)"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label for="end-date">To</label>
            <b-form-datepicker
              id="end-date"
              v-model="endDate"
              reset-button
              class="mb-2"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              @input="startDate=setStartDate(startDate, endDate)"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label for="departure-date">Departure Date</label>
            <b-form-datepicker
              id="departure-date"
              v-model="departureDate"
              reset-button
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              class="mb-2"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="align-self-center"
          >
            <div class="d-flex align-content-center">
              <b-button
                variant="primary"
                @click="getBookingList()"
              >
                <b-spinner
                  v-if="isQueryRequested"
                  small
                />
                Set Period
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-input
              v-model="passportNumber"
              class="d-inline-block mr-1"
              placeholder="Search by passport number..."
              @input="debounceSearch(meta.offset = 0, getBookingList)"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          />
          <!--search-->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @input="debounceSearch(meta.offset = 0, getBookingList)"
              />
              <v-select
                v-model="statusQuery"
                :options="status"
                :reduce="option => option.value"
                :clearable="true"
                class="status-filter-select"
                placeholder="Select Status"
                @input="getBookingList()"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        id="reportTable"
        :items="bookingList"
        :fields="tableColumns"
        :busy="isTableLoading"
        primary-key="id"
        empty-text="No matching records found"
        responsive
        show-empty
        class="position-relative"
      >
        <template #table-busy>
          <div class="text-center text-primary m-2">
            <b-spinner class="align-middle" />
            <strong> Loading...</strong>
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`${data.item.id}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mx-1 cursor-pointer"
              @click="showFlightDetails(data.item)"
            />
            <b-dropdown
              v-if="['BOOKING_HOLD', 'BOOKING_SUCCESS', 'TICKETING_SUCCESS', 'TICKET_ISSUED'].includes(data.item.booking_status)"
              variant="link"
              toggle-class="p-0"
              class="action-menu"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="data.item.booking_status === 'BOOKING_HOLD'"
                v-b-modal.flight-modal
                @click="getRequestedAction({ product_reference: data.item.booking_id }, 'Send Payment Link', 'send payment link for',
                                           'user_journey/offline_invoice/assisted_payment/',
                                           'post', false)"
              >
                <feather-icon icon="SendIcon" />
                <span class="align-middle ml-50">Send Payment Link</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.booking_status === 'BOOKING_SUCCESS'"
                v-b-modal.flight-modal
                @click="getRequestedAction({ id: data.item.id }, 'Ticket Issue', 'issue ticket for',
                                           'flight/ticketing/',
                                           'post', false)"
              >
                <feather-icon icon="FileIcon" />
                <span class="align-middle ml-50">Ticket Issue</span>
              </b-dropdown-item>
              <template v-if="data.item.booking_status === 'TICKETING_SUCCESS' || data.item.booking_status === 'TICKET_ISSUED' ">
                <b-dropdown-item
                  v-b-modal.flight-modal
                  @click="getRequestedAction({ id: data.item.id }, 'Download Ticket', 'download ticket for',
                                             'flight/admin_services/send_ticketing_mail/',
                                             'post', true)"
                >
                  <feather-icon icon="DownloadIcon" />
                  <span class="align-middle ml-50">Download Ticket</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.flight-modal
                  @click="getRequestedAction({ id: data.item.id }, 'Send Mail', 'send mail for',
                                             'flight/admin_services/send_ticketing_mail/',
                                             'post', false)"
                >
                  <feather-icon icon="MailIcon" />
                  <span class="align-middle ml-50">Send Mail</span>
                </b-dropdown-item>
              </template>
              <b-dropdown-item
                v-if="data.item.booking_status === 'BOOKING_HOLD'
                  || data.item.booking_status === 'BOOKING_SUCCESS'"
                v-b-modal.flight-modal
                @click="getRequestedAction({ id: data.item.id }, 'Cancel Booking', 'cancel',
                                           'flight/booking/cancel/',
                                           'post', false)"
              >
                <feather-icon icon="XIcon" />
                <span class="align-middle ml-50">Cancel Booking</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
        <template #cell(booked_by)="data">
          {{ data.item.agent ? data.item.agent : data.item.user ? data.item.user.email : 'N/A' }}
        </template>
        <template #cell(booking_status)="data">
          <b-badge
            pill
            class="cursor-pointer"
            :variant="formatClassName(data.item.booking_status)"
          >
            {{ formatStatus(data.item.booking_status) }}
          </b-badge>
        </template>
        <template #cell(trips)="data">
          <span
            v-for="(flight, f) in data.item.flight_details.flights"
            :key="f"
            class="names"
          >
            <span>{{ flight.origin }} - {{ flight.destination }}</span>
            <template v-if="f < data.item.flight_details.flights.length - 1">, </template>
          </span>
        </template>
      </b-table>
      <TablePagination
        :offset="meta.offset"
        :total="meta.total"
        :limit="meta.limit"
        :list-data="bookingList"
        @paginate="paginate"
      />
    </b-card>
    <FlightBookingDetails
      :flight-data="flightData"
    />
    <FlightActions
      :modal-data="flightActionsData"
      @getBookingList="getBookingList"
    />
  </div>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'
import calendar from '@/mixins/calendar'
import TablePagination from '@/components/common/TablePagination.vue'
import FlightBookingDetails from '@/views/bookings/flight/FlightBookingDetails.vue'
import FlightActions from '@/views/bookings/flight/FlightActions.vue'

export default {
  name: 'FlightBookingList',
  components: { TablePagination, FlightBookingDetails, FlightActions },
  mixins: [helper, calendar],
  data() {
    return {
      meta: {
        offset: 0,
        limit: 10,
        total: 0,
      },
      isTableLoading: false,
      bookingList: [],
      flightData: {},
      isQueryRequested: false,
      startDate: '',
      endDate: '',
      departureDate: '',
      searchQuery: '',
      statusQuery: '',
      passportNumber: '',
      flightActionsData: {},
      status: [
        {
          label: 'On Hold',
          value: 'BOOKING_HOLD',
        },
        {
          label: 'Pending Bookings',
          value: 'BOOKING_SUCCESS',
        },
        {
          label: 'Cancelled Bookings',
          value: 'BOOKING_CANCELLED',
        },
        {
          label: 'Confirmed Tickets',
          value: 'TICKETING_SUCCESS,TICKET_ISSUED',
        },
        {
          label: 'Cancelled Tickets',
          value: 'TICKET_CANCELLED',
        },
        {
          label: 'Failed Tickets',
          value: 'TICKETING_FAILED',
        },
      ],
      tableColumns: [
        { key: 'actions', label: '' },
        {
          key: 'created_at', label: 'Booking Time', thClass: 'lg', formatter: value => this.formatDate(value, 'DD MMM YYYY, hh:mm A'),
        },
        { key: 'trips', label: 'Flight', thClass: 'md' },
        { key: 'booking_status', label: 'Status' },
        { key: 'booking_id', label: 'Booking ID', thClass: 'md' },
        { key: 'booked_by', label: 'Booked By' },
        {
          key: 'gds_pnr', label: 'Airline PNR', thClass: 'md', formatter: value => value || 'N/A',
        },
        { key: 'paxes', label: 'Paxes', formatter: value => value.length },
      ],
    }
  },
  computed: {
    filterQuery() {
      const startDate = this.startDate ? `&date_from=${this.startDate}` : ''
      const endDate = this.endDate ? `&date_to=${this.endDate}` : ''
      const departure = this.departureDate ? `&departure_date=${this.departureDate}` : ''
      const search = this.searchQuery ? `&search=${this.searchQuery}` : ''
      const passport = this.passportNumber ? `&passport=${this.passportNumber}` : ''
      const status = this.statusQuery ? `&status=${this.statusQuery}` : ''
      return `${startDate}${endDate}${departure}${search}${passport}${status}`
    },
  },
  mounted() {
    this.getBookingList()
  },
  methods: {
    getBookingList() {
      this.isTableLoading = true
      api.getData(`flight/go_biz/booking/?offset=${this.meta.offset}${this.filterQuery}`, true)
        .then(response => {
          if (response.data.status) {
            this.bookingList = response.data.result.results
            this.meta = response.data.result.meta
          }
        }).catch().finally(() => {
          this.isTableLoading = false
        })
    },
    paginate(offset) {
      this.meta.offset = offset
      this.getBookingList()
    },
    showFlightDetails(data) {
      this.flightData = data
      this.$root.$emit('bv::toggle::collapse', 'flight-details-view')
    },
    getRequestedAction(data, title, text, apiEndpoint, apiMethod, isDownload) {
      this.flightActionsData = {
        data,
        title,
        text,
        apiEndpoint,
        apiMethod,
        isDownload,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
::v-deep .status-filter-select {
  min-width: 220px;
}
</style>
